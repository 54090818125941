import React from 'react';
import { Shield, Brain, Zap, ChevronRight, Mail, DollarSign, Clock, Users, CheckCircle } from 'lucide-react';
import './index.css';

const LandingPage = () => {
  const benefits = [
    {
      title: "Reduce Costos Operativos",
      description: "Arquitecturas bien diseñadas reducen costos de mantenimiento hasta en un 40%",
      icon: DollarSign
    },
    {
      title: "Mejora Calidad de Vida",
      description: "Menos incidentes nocturnos, menor estrés y mejor balance vida-trabajo",
      icon: Users
    },
    {
      title: "Acelera Time-to-Market",
      description: "Desarrollo más rápido y predecible con arquitecturas bien definidas",
      icon: Clock
    }
  ];

  const testimonials = [
    {
      quote: "Redujimos nuestros incidentes en producción en un 70% después de implementar las recomendaciones arquitectónicas.",
      author: "María González",
      role: "CTO",
      company: "FinTech Latam"
    },
    {
      quote: "Nuestro equipo ahora puede implementar nuevas características en días en lugar de semanas.",
      author: "Carlos Rodríguez",
      role: "Lead Developer",
      company: "E-commerce Solutions"
    }
  ];

  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <section className="hero">
        <div className="container">
          <div className="text-center fade-in">
            <h1>
              Arquitectura de Software que
              <span>Impulsa tu Negocio</span>
            </h1>
            <p>
              ¿Tus sistemas están generando más problemas que soluciones? Transformamos arquitecturas complejas y costosas en soluciones eficientes y escalables.
            </p>
            <div className="button-container">
              <button className="button button-primary">
                Consulta Gratuita
                <ChevronRight />
              </button>
              <button className="button button-secondary">
                Ver Casos de Éxito
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* Pain Points Section */}
      <section className="pain-points">
        <div className="container">
          <div className="text-center">
            <h2 className="section-title">¿Te identificas con estos problemas?</h2>
            <div className="pain-points-grid">
              <div className="pain-point-card">
                <h3>Costos Elevados</h3>
                <p>Mantenimiento costoso y continuas "soluciones parche"</p>
              </div>
              <div className="pain-point-card">
                <h3>Desarrollo Lento</h3>
                <p>Cambios simples toman semanas en implementarse</p>
              </div>
              <div className="pain-point-card">
                <h3>Incidentes Frecuentes</h3>
                <p>Caídas del sistema y problemas en producción</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="benefits">
        <div className="container">
          <div className="text-center">
            <h2 className="section-title">Beneficios de una Arquitectura Sólida</h2>
          </div>
          <div className="benefits-grid">
            {benefits.map((benefit, index) => (
              <div key={index} className="benefit-card fade-in">
                <div className="benefit-icon">
                  <benefit.icon />
                </div>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section className="services">
        <div className="container">
          <div className="text-center">
            <h2 className="section-title">Nuestros Servicios</h2>
          </div>
          <div className="services-grid">
            <div className="service-card fade-in">
              <div className="service-content">
                <div className="service-icon">
                  <Brain />
                </div>
                <h3>Diseño Arquitectónico</h3>
                <p>Creamos arquitecturas escalables que crecen con tu negocio. Incluye documentación detallada y capacitación del equipo.</p>
                <ul className="service-list">
                  <li><CheckCircle /> Diseño de microservicios</li>
                  <li><CheckCircle /> Patrones de integración</li>
                </ul>
              </div>
            </div>

            <div className="service-card fade-in">
              <div className="service-content">
                <div className="service-icon">
                  <Shield />
                </div>
                <h3>Evaluación y Auditoría</h3>
                <p>Análisis profundo de tu arquitectura actual con recomendaciones específicas de mejora.</p>
                <ul className="service-list">
                  <li><CheckCircle /> Evaluación de escalabilidad</li>
                  <li><CheckCircle /> Análisis de costos operativos</li>
                </ul>
              </div>
            </div>

            <div className="service-card fade-in">
              <div className="service-content">
                <div className="service-icon">
                  <Zap />
                </div>
                <h3>Optimización y Modernización</h3>
                <p>Transformación gradual de sistemas legacy en arquitecturas modernas y mantenibles.</p>
                <ul className="service-list">
                  <li><CheckCircle /> Migración a microservicios</li>
                  <li><CheckCircle /> Modernización cloud-native</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="testimonials">
        <div className="container">
          <div className="text-center">
            <h2 className="section-title">Lo que Dicen Nuestros Clientes</h2>
          </div>
          <div className="testimonials-grid">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="testimonial-card fade-in">
                <p className="testimonial-quote">{testimonial.quote}</p>
                <p className="testimonial-author">{testimonial.author}</p>
                <p className="testimonial-role">{testimonial.role} - {testimonial.company}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section className="contact">
        <div className="container">
          <div className="text-center">
            <h2 className="section-title">¿Listo para Transformar tu Arquitectura?</h2>
            <p>Agenda una consulta gratuita y descubre cómo podemos ayudarte</p>
            <button className="button">
              <Mail />
              Agendar Consulta Gratuita
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LandingPage;